* {
  margin: 0;
  padding: 0;
}

:root {
  --red-color: #dc2626;
  --blue-color: #3b82f6;
  --gray-color: #808080;
  --green-color: #008000;
  --black-color: #000000;
  --white-color: #ffffff;
  --primary-color: #15cdd3;
  --light-gray-color: #e5e7eb;
  --ocean-blue-color: #174291;
  --light-blue-color: #12b7bc;
  --bright-ocean-blue-color: #1f5dc6;
  --primary-dark-color: rgb(13, 142, 147);

  --font-size-small: 13px;
  --font-size-default: 15px;
  --font-size-medium: 18px;
  --font-size-large: 24px;
  --font-size-xlarge: 27px;
  --font-size-xxlarge: 33px;
}

.img-Item {
  width: 50px !important;
  height: 50px !important;
  border-radius: 10px;
  object-fit: cover;
}

.img-Item-Circle {
  width: 100px !important;
  height: 100px !important;
  border-radius: 100px;
  object-fit: cover;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 0.3rem;
  background: rgb(30 30 31 / 45%);
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(30 30 31 / 45%);
}

.text-underline {
  cursor: pointer;
  text-decoration: underline;
}

.hyperlink {
  color: blue;
  text-decoration: underline !important;
}

.bg-gray-color {
  background-color: var(--gray-color) !important;
}

.button-status-draft {
  background-color: var(--primary-dark-color);
  color: #fff;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  line-height: 1.75;
  padding: 0rem 1rem;
  min-width: 7rem;
}

.button-status-succes {
  background-color: var(--green-color);
  color: #fff;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  line-height: 1.75;
  padding: 0rem 1rem;
  min-width: 7rem;
}


.inputQuantity {
  width: 40%;
  vertical-align: inherit;
}

.oldPrice {
  color: red;
  text-decoration: line-through;
}

.list-item {
  position: absolute;
  top: 17vh;
  bottom: 17vh;
  /* height: 66vh; */
  left: 1vw;
  width: 69vw;
}

.left-container {
  position: absolute;
  top: 17vh;
  bottom: 17vh;
  right: 1vw;
  height: 80vh;
  width: 30vw;
}

.frame-grove-vnt {
  border: 1px solid;
}

.column-grove-vnt {
  float: left;
  text-align: center;
  line-height: 1;
}

.content-grove-vnt {
  margin-bottom: 3px;
}

.row-grove-vnt:after {
  content: "";
  display: table;
  clear: both;
}

.row-grove-vnt {
  clear: both;
}

.promotionTable {
  position: absolute;
  top: 64vh;
  left: 2vh;
  font-size: 14px;
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.stickyHeader {
  position: sticky;
  top: 0;
  background: white;
  z-index: 1;
}

.swal2-container,
.custom-sweetalert2 {
  z-index: 99999 !important;
}

.swal2-html-container {
  font-size: 1rem !important;
}

.custom-sweetalert2-btn-cancel {
  font-weight: 700 !important;
  font-size: 0.875rem !important;
  background: linear-gradient(195deg, #747b8a, #495361) !important;
}

.custom-sweetalert2-btn-ok {
  font-weight: 700 !important;
  font-size: 0.857rem !important;
  background: linear-gradient(195deg, #42424a, #191919) !important;
}

.swal2-styled.swal2-confirm:focus {
  box-shadow: none !important;
}